import React from 'react';
import { Trans, useTranslation } from "react-i18next"

import { JobApplyForm } from '../../components/job-apply-form-modal';
import SEO from '../../components/seo';
import styles from './job.module.css';

const JobPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title="Operations Manager" />
      <div className={styles.bgJob}>
        <div className={styles.job}>
          <h1 className={styles.textHeader}>Event Manager</h1>
          <p className={styles.text}>{t("careers.eventManager.text1")}</p>
          <p className={styles.text}>
            {t("careers.eventManager.text2")}
          </p>
          <p className={styles.text}>
            {t("careers.eventManager.text3")}
          </p>

          <h3 className={styles.textSubHeader}>{t("careers.eventManager.textSubHeader")}</h3>
          <ul className={styles.textUl}>
            <Trans i18nKey="careers.eventManager.list1">
            <li className={styles.newList}>
              Organizaci našich ochutnávacích akcí a workshopů v fitness
              centrech a kancelářích
            </li>
            <li className={styles.newList}>
              Zajištění potřebných materiálů (brožury, letáky atd.)
            </li>
            <li className={styles.newList}>
              Komunikaci s našimi obchodními partnery
            </li>
            <li className={styles.newList}>
              Koordinaci týmu (hostesek, fotografů, sales manažerů)
            </li>
            </Trans>
          </ul>

          <Trans i18nKey="careers.eventManager.list2">
          <h3 className={styles.textSubHeader}>Jak bychom tě představovali?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Máš výborné komunikační a organizační schopnosti
            </li>
            <li className={styles.newList}>
              Máš prokazatelné zkušenosti z organizaci eventů různých velikosti
            </li>
            <li className={styles.newList}>
              Dokážeš si zachovat chladnou hlavu i ve stresových situacích
            </li>
            <li className={styles.newList}>Baví Tě zdravý životní styl</li>
            <li className={styles.newList}>Jsi otevřený/á novým věcem</li>
          </ul>
          </Trans>
          <Trans i18nKey="careers.eventManager.list3">
          <h3 className={styles.textSubHeader}>Co získáš od nás?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>Part-time uplatnění</li>
            <li className={styles.newList}>Příspěvky na vzdělávací kurzy</li>
            <li className={styles.newList}>
              Motivující finanční ohodnocení, měsíční a kvartální bonusy
            </li>
            <li className={styles.newList}>
              Možnost kariérního růstu v rámci marketingového nebo sales
              oddělení
            </li>
          </ul>
          </Trans>

          <p className={styles.text}>
            {t("careers.text2")}
          </p>

          <h3 className={styles.textSubHeader}>
            {t("careers.textSubHeader2")}
          </h3>
          <h3 className={styles.textSubHeader}>
            {t("careers.textSubHeader3")}
          </h3>
          <a className={styles.jobsEmail} href="mailto:jobs@nutritionpro.cz">
            jobs@nutritionpro.cz
          </a>
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
          {t("careers.backButton")}
          </a>
          {/* <div style={{ marginTop: "40px" }}>
            <JobApplyForm formName="JobApplyForm_eventManager" />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default JobPage
